/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const AnchorElType = PropTypes.oneOfType([
  PropTypes.object,
  // eslint-disable-next-line no-undef
  PropTypes.instanceOf(Element),
  PropTypes.shape({
    clientWidth: PropTypes.number.isRequired,
    clientHeight: PropTypes.number.isRequired,
    getBoundingClientRect: PropTypes.func.isRequired,
  }),
]);

export { AnchorElType };
