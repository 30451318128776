import { Button, Drawer, IconButton, Slider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import PropTypes from 'prop-types';
import DoubleInputDateRangePicker from '../../components/datePickers/DoubleInputDateRangePicker';
import { formatMoneyWithSign } from '../../helpers';

const ConfirmingPortfolioFilters = ({ actionFilters, setActionFilters, ...props }) => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState([0, 100000000]);
  const [hasToChange, setHasToChange] = useState(false);
  const handleChange = (event, newValue) => {
    setAmount(newValue);
    setTimeout(() => setHasToChange(true), 500);
  };
  useEffect(() => {
    if (hasToChange) {
      if (amount[1] === 100000000) {
        setActionFilters((old) => ({ ...old, amountBetween: [amount[0], null] }));
      }
      if (amount[0] === 0) {
        setActionFilters((old) => ({ ...old, amountBetween: [null, amount[1]] }));
      }
      setActionFilters((old) => ({ ...old, amountBetween: amount }));
      setHasToChange(false);
    }
  }, [hasToChange]);

  const getAriaValueText = (value) => {
    if (value === 100000000) {
      return 'Sin límite superior';
    }
    if (value === 0) {
      return 'Sin límite inferior';
    }
    return formatMoneyWithSign(value);
  };
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          size="small"
          sx={{ bgcolor: 'white' }}
          color="text"
          endIcon={<ArrowDropDown color="primary" />}
          onClick={() => setOpen(true)}
          {...props}
        >
          Filtros
        </Button>
      </Stack>
      <Drawer
        open={open}
        onClose={() => { setOpen(false); }}
        anchor="right"
        PaperProps={{
          sx: { width: { xs: '100%', md: '50%', lg: '30%' } },
        }}
        sx={{ zIndex: 1100, position: 'absolute' }}
      >
        <Stack spacing={5} width="80%" alignSelf="center">
          <Stack mt={2} direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <IconButton onClick={() => setOpen(false)} size="large">
                <ArrowBackIos />
              </IconButton>
              <Typography color="primary" variant="h6">
                Filtros
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Button
                id="clean-filters"
                variant="link"
                onClick={() => { setActionFilters({}); setAmount([0, 100000000]); }}
              >
                Limpiar filtros
              </Button>
            </Stack>
          </Stack>
          <DoubleInputDateRangePicker
            datetime
            title="Fecha de creación de la nómina:"
            dateFrom={actionFilters.createdAt_Gte}
            dateTo={actionFilters.createdAt_Lte}
            setDateFrom={
            (value) => setActionFilters((old) => ({ ...old, createdAt_Gte: value }))
          }
            setDateTo={
            (value) => setActionFilters((old) => ({ ...old, createdAt_Lte: value }))
          }
          />
          <DoubleInputDateRangePicker
            title="Fecha de vencimiento:"
            dateFrom={actionFilters.operations_ExpirationDate_Gte}
            dateTo={actionFilters.operations_ExpirationDate_Lte}
            setDateFrom={
            (value) => setActionFilters((old) => ({ ...old, operations_ExpirationDate_Gte: value }))
          }
            setDateTo={
            (value) => setActionFilters((old) => ({ ...old, operations_ExpirationDate_Lte: value }))
          }
          />
          <Stack spacing={1}>
            <Typography variant="h6">
              Filtrar por monto de la nómina
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption">&gt;={getAriaValueText(amount[0])}</Typography>
              <Typography variant="caption">&lt;={getAriaValueText(amount[1])}</Typography>
            </Stack>
            <Slider
              getAriaLabel={() => 'Temperature range'}
              value={amount}
              onChange={handleChange}
              getAriaValueText={getAriaValueText}
              valueLabelFormat={getAriaValueText}
              valueLabelDisplay="auto"
              step={100000}
              max={100000000}
              sx={{ width: '80%', alignSelf: 'center' }}
            />
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

ConfirmingPortfolioFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actionFilters: PropTypes.object,
  setActionFilters: PropTypes.func,
};

ConfirmingPortfolioFilters.defaultProps = {
  actionFilters: {},
  setActionFilters: () => {},
};

export default ConfirmingPortfolioFilters;
