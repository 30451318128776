import { Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { formatDayMonthYearSlash, formatMoney, formatRut, toPercentage } from '../../../helpers';
import { SelectBankAccount } from '../../../components/selects';
import { CONFIRMING_PAYROLLS } from '../../../graphql';
import CessionStatus from '../CessionStatus';
import { AbstratStatus } from '../../../components/status';
import PendingConfirmingActions from './views/PendingConfirmingActions';
import ChangeConfirmingOperations from './views/ChangeConfirmingOperations';

export default (updateOperation) => [
  {
    field: 'company',
    headerName: 'Proveedor',
    sortable: true,
    renderCell: ({ row }) => (
      <Stack width={320}>
        <Typography noWrap>
          {row.supplierName}
        </Typography>
        <Typography>
          {formatRut(row.supplierRut)}
        </Typography>
      </Stack>
    ),
    width: 80,
  }, {
    field: 'totalAmount',
    headerName: 'Monto total',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography textAlign="left">
        ${formatMoney(row.totalAmount)}
      </Typography>
    ),
    width: 40,
  }, {
    field: 'invoiceCount',
    headerName: 'Cantidad de facturas',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography textAlign="left">
        {row.totalCount}
      </Typography>
    ),
    width: 40,
  },
  {
    field: 'expirationDate',
    headerName: 'Fecha de pago',
    sortable: true,
    renderCell: ({ row }) => {
      const distance = moment().to(row.expirationDate);
      return (
        <>
          <Typography textAlign="center">
            {formatDayMonthYearSlash(row.expirationDate)}
          </Typography>
          <Typography>
            {distance}
          </Typography>
        </>
      );
    },
    width: 40,
  }, {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    renderCell: ({ row }) => (
      <TextField
        InputProps={{ sx: { background: 'white' } }}
        defaultValue={row.contactEmail || ''}
        onBlur={({ target }) => {
          updateOperation({ contactEmail: target.value, bankAccountId: row.bankAccount?.id, row });
        }}
      />
    ),
    width: 80,
  }, {
    field: 'bankAccount',
    headerName: 'Cuenta Bancaria',
    sortable: true,
    renderCell: ({ row }) => (
      <SelectBankAccount
        sx={{ backgroundColor: 'white' }}
        fullWidth
        hideText
        width="50%"
        bankId={row.bankAccount?.id}
        setBankId={(id) => updateOperation(
          { contactEmail: row.contactEmail, bankAccountId: id, row },
        )}
        customCompany={row.confirmingSet[0]?.company}
        customBankAccounts={row.confirmingSet[0]?.company.bankAccounts || []}
        refetchQueriesOnCreation={[CONFIRMING_PAYROLLS]}
      />
    ),
    width: 80,
  },
  {
    field: 'numberOfDocumentsWithCessionInfo',
    headerName: 'Cantidad de facturas',
    sortable: true,
    renderCell: ({ row }) => (
      <AbstratStatus
        title={`${row.totalCount} facturas`}
        row={row.statusInfo}
        alignSelf="left"
      />
    ),
    width: 40,
  }, {
    field: 'cessionStatus',
    headerName: 'Estado',
    sortable: true,
    renderCell: ({ row }) => <CessionStatus statusInfo={row.statusInfo} />,
    width: 40,
  }, {
    field: 'mailReminder',
    headerName: 'Recordatorio',
    sortable: true,
    renderCell: ({ row }) => (
      <PendingConfirmingActions
        confirmingIds={
        row.confirmingSet?.flatMap(({ id }) => id)
        }
      />
    ),
    width: 40,
  },
  {
    field: 'monthlyRate',
    headerName: 'Tasa',
    sortable: false,
    renderCell: ({ row }) => (
      <Typography>
        {toPercentage(row.monthlyRate / 100)}
      </Typography>
    ),
    width: 20,
  },
  {
    field: 'operationConditionsEdit',
    headerName: 'Cambiar condiciones de operación',
    sortable: false,
    renderCell: ({ row }) => (
      <ChangeConfirmingOperations
        confirmingOperationIds={[row.id]}
        defaultDate={row.expirationDate}
      />
    ),
    width: 20,
  },
];
