import { Stack, Tooltip } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { EXPORT_CONFIRMING_PAYROLL } from '../../../../graphql';
import { openUrlAndDownload } from '../../../../helpers';
import { DownloadExcelIconButton } from '../../../../components/buttons';

const HistoricalActions = ({ payrollId }) => {
  const [exportConfirmingPayroll, { loading: loadingExcel }] = useLazyQuery(
    EXPORT_CONFIRMING_PAYROLL,
    { onCompleted: (data) => openUrlAndDownload(data.exportConfirmingPayroll, 'payrollWorkbook') },
  );
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      {payrollId && (
      <DownloadExcelIconButton
        title="Descargar excel de nómina"
        loading={loadingExcel}
        downloadFunction={() => exportConfirmingPayroll({ variables: { payrollId } })}
      />
      )}
      <Tooltip
        title="Descargar comprobante de pago"
      >
        <LoadingButton
          color="primary"
          size="small"
          disabled
        >
          <u>Comprobante de pago</u>
        </LoadingButton>
      </Tooltip>
    </Stack>
  );
};

HistoricalActions.propTypes = {
  payrollId: PropTypes.string.isRequired,
};

export default HistoricalActions;
