import { Link, Stack, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import DynamicFeedOutlined from '@mui/icons-material/DynamicFeedOutlined';
import Block from '@mui/icons-material/Block';
import { formatDayMonthYearSlash, formatMoney, formatRut } from '../../../helpers';
import { AbstratStatus } from '../../../components/status';
import CessionStatus from '../CessionStatus';
import PendingConfirmingActions from './views/PendingConfirmingActions';
import CurrentPayrollRowActions from './views/CurrentPayrollRowActions';
import HistoricalActions from './views/HistoricalActions';
import PayrollActions from './views/PayrollActions';
import ChangeConfirmingOperations from './views/ChangeConfirmingOperations';
import ApproveConfirming from './views/ApproveConfirming';

export default [
  {
    field: 'id',
    headerName: 'ID',
    sortable: true,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/app/payroll-profile?payrollId=${row.id}`}>
        {row.id}
      </Link>
    ),
    width: 80,
  },
  {
    field: 'company',
    headerName: 'Empresa',
    sortable: true,
    renderCell: ({ row }) => (
      <Stack direction="row" spacing={1}>
        <Typography>
          {row.companyName} ({formatRut(row.companyRut)})
        </Typography>
        {row.companyBlacklist && (
          <Tooltip title="Empresa en la Blacklist">
            <Block loading sx={{ width: 15, height: 15 }} color="error" />
          </Tooltip>
        )}
      </Stack>
    ),
    width: 80,
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de creación',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography>
        {formatDayMonthYearSlash(row.createdAt)}
      </Typography>
    ),
    width: 80,
  },
  {
    field: 'totalAmount',
    headerName: 'Monto',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography>
        ${formatMoney(row.totalAmount)}
      </Typography>
    ),
    width: 80,
  },
  {
    field: 'numberOfOperations',
    headerName: 'Cantidad Proveedores',
    sortable: true,
    renderCell: ({ row }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginRight="50%"
      >
        <Typography>
          {row.supplierCount}
        </Typography>
        {!row.areAllOperationsEqual
        && (
        <Tooltip title="👀 Esta operación tiene distintas condiciones según proveedor">
          <DynamicFeedOutlined color="warning" />
        </Tooltip>
        )}
      </Stack>
    ),
    width: 80,
  },
  {
    field: 'numberOfDocuments',
    headerName: 'Cantidad Facturas',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography>
        {row.totalCount}
      </Typography>
    ),
    width: 80,
  },
  {
    field: 'numberOfDocumentsWithCessionInfo',
    headerName: 'Cantidad Facturas',
    sortable: false,
    renderCell: ({ row }) => (
      <AbstratStatus
        title={`${row.totalCount} facturas`}
        row={row.statusInfo}
        alignSelf="left"
      />
    ),
    width: 80,
  },
  {
    field: 'cessionStatus',
    headerName: 'Estado',
    sortable: false,
    renderCell: ({ row }) => <CessionStatus statusInfo={row.statusInfo} />,
    width: 80,
  },
  {
    field: 'mailReminder',
    headerName: 'Recordatorio',
    sortable: false,
    renderCell: ({ row }) => (
      <PendingConfirmingActions
        payrollId={row.id}
        confirmingIds={row.operations?.flatMap(
          ({ confirmingSet }) => confirmingSet?.flatMap(
            ({ id }) => id,
          ),
        )}
      />
    ),
    width: 80,
  },
  {
    field: 'currentActions',
    headerName: '',
    sortable: false,
    renderCell: ({ row }) => (
      <CurrentPayrollRowActions
        payrollId={row.id}
      />
    ),
    width: 80,
  },
  {
    field: 'historicalActions',
    headerName: '',
    sortable: false,
    renderCell: ({ row }) => (
      <HistoricalActions
        payrollId={row.id}
      />
    ),
    width: 80,
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    sortable: false,
    renderCell: ({ row }) => (
      <PayrollActions row={row} />
    ),
    width: 80,
  },
  {
    field: 'payrollConditonsEdit',
    headerName: 'Condiciones de nómina',
    sortable: false,
    renderCell: ({ row }) => (
      <Stack direction="row">
        <ChangeConfirmingOperations
          confirmingOperationIds={row.operations.map(({ id }) => id)}
        />
        <ApproveConfirming payroll={row} />
      </Stack>
    ),
    width: 20 },
];
