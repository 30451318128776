import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, Stack, Tooltip, Typography, alpha } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import ConfirmingCreditLine from './ConfirmingCreditLine';
import ConfirmingUploadSidebar from './ConfirmingUploadSidebar';
import { CONFIRMING_PAYROLL } from '../../../../graphql';
import RoundedWhiteBox from '../../../../components/boxes/RoundedWhiteBox';
import { PaperHeader } from '../../../../components/headers';
import ConfirmingPortfolioOperations from '../../ConfirmingPortfolioOperations';
import { formatMoney } from '../../../../helpers';

const ConfirmingUploadFunding = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const payrollId = searchParams.get('payrollId');
  const theme = useTheme();
  const { data } = useQuery(
    CONFIRMING_PAYROLL,
    {
      variables: { payrollId },
    },
  );
  const isInitiated = useMemo(() => data?.confirmingPayroll?.status?.status === 'INITIATED', [data]);
  const disabled = useMemo(() => data?.confirmingPayroll.operations.filter(
    ({ contactEmail, bankAccount }) => (!contactEmail || !bankAccount),
  ).length, [data]);
  if (!data) {
    return (<CircularProgress sx={{ marginLeft: '50%', marginTop: '25%' }} />
    );
  }
  return (
    <RoundedWhiteBox mx={4} height="90%">
      <PaperHeader
        viewTitle={isInitiated ? `Nómina ${payrollId}` : 'Operar nómina'}
        finder={{
          searchPlaceHolder: 'Buscar',
        }}
        showGoBack
      />
      <Stack direction="row">
        <ConfirmingCreditLine boxShadow={2} margin="auto" width="60%" p={2} />
        {isInitiated || (
        <Tooltip
          sx={{ alignSelf: 'right' }}
          title={disabled ? 'Falta información bancaria o personal en la evaluación' : null}
        >
          <Grid>
            <Button
              size="small"
              variant="contained"
              disabled={disabled}
              onClick={() => setOpen(true)}
            >
              Continuar →
            </Button>
          </Grid>
        </Tooltip>
        )}
      </Stack>
      <ConfirmingPortfolioOperations
        row={data.confirmingPayroll}
        customHeaders={isInitiated ? ['company',
          'totalAmount',
          'numberOfDocumentsWithCessionInfo',
          'cessionStatus',
          'mailReminder',
        ] : undefined}
      />
      {isInitiated || (
      <>
        <Stack
          p={4}
          bottom={16}
          left={16}
          right={16}
          direction="row"
          sx={{
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            borderRadius: '8px',
          }}
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            spacing={8}
          >
            <Stack spacing={1}>
              <Typography variant="h6">
                <b>
                  Monto total
                </b>
              </Typography>
              <Typography variant="h5">
                {formatMoney(data.confirmingPayroll.totalAmount)}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h6">
                <b>
                  Cantidad Proveedores
                </b>
              </Typography>
              <Typography variant="h5">
                {data.confirmingPayroll.supplierCount}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h6">
                <b>
                  Cantidad Facturas
                </b>
              </Typography>
              <Typography variant="h5">
                {data.confirmingPayroll.totalCount}
              </Typography>
            </Stack>
          </Stack>
          <Tooltip
            sx={{ alignSelf: 'right' }}
            title={disabled ? 'Falta información bancaria o personal en la evaluación' : null}
          >
            <Grid>
              <Button
                size="small"
                variant="contained"
                disabled={disabled}
                onClick={() => setOpen(true)}
              >
                Continuar →
              </Button>
            </Grid>
          </Tooltip>
        </Stack>
        <ConfirmingUploadSidebar
          open={open}
          setOpen={setOpen}
          row={data.confirmingPayroll}
          simulationView={false}
        />
      </>
      )}
    </RoundedWhiteBox>
  );
};

export default ConfirmingUploadFunding;
