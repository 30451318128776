import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import ConfirmingUploadSidebar from './ConfirmingUploadSidebar';
import { ConfirmingPayrollType } from '../../../../propTypes';

const PayrollActions = ({ row }) => {
  const [open, setOpen] = useState(false);
  return (
    <Stack direction="row">
      <Button
        size="small"
        onClick={() => setOpen(true)}
      >
        Simular
      </Button>
      <Button
        size="small"
        to={{
          pathname: '/app/payroll-profile',
          search: `?payrollId=${row.id}`,
        }}
        component={Link}
      >
        Financiar →
      </Button>
      <ConfirmingUploadSidebar
        open={open}
        setOpen={setOpen}
        row={row}
      />
    </Stack>
  );
};

PayrollActions.propTypes = {
  row: ConfirmingPayrollType.isRequired,
};

export default PayrollActions;
