import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const CessionStatus = ({ statusInfo }) => {
  const { PROCESSING, PENDING_TRANSFER } = statusInfo;
  if (PROCESSING > 0) {
    return (
      <Typography color="error.main">
        Pendiente de cesión
      </Typography>
    );
  } if (PENDING_TRANSFER > 0) {
    return (
      <Typography color="warning.main">
        Pendiente de giro
      </Typography>
    );
  }
  return (
    <Typography color="success.main">
      Girada
    </Typography>
  );
};

CessionStatus.propTypes = {
  statusInfo: PropTypes.shape({
    PROCESSING: PropTypes.number,
    PENDING_TRANSFER: PropTypes.number,
  }),
};

CessionStatus.defaultProps = {
  statusInfo: {},
};

export default CessionStatus;
